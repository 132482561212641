.p {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
}

.p-projectList {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.pi-projectItem {
    border-radius: 15px;
    width: 400px;
    height: fit-content;
    margin: 40px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: black;
}

.pi-projectItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
}

.pi-projectItem .pi-bg-Image {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pi-projectItem h1 {
    font-size: 25px;
    margin-top: 10px;
}

.p-div {
    width: 75%;
    margin-bottom: 20px;
}

.pi-icon {
    color: royalblue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    object-fit: cover;
    margin: 10px;
}

@media only screen and (max-width: 1000px) {
    .p-projectList {
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .pi-projectItem {
        border-radius: 15px;
        width: fit-content;
        height: fit-content;
        margin: 15px 0px 0px;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
        text-align: center;
        color: black;
    }
}