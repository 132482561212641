.i {
  display: flex;
  height: 90vh;
}

.i-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-right {
  flex: 1;
  position: relative;
}

.i-left-wrapper {
  padding: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.i-intro {
  font-size: 30px;
  font-weight: 300;
}

.i-name {
  font-size: 60px;
}

.i-title {
  height: 50px;
  overflow: hidden;
  margin-left: 10px;

}

.i-container {
  display: flex;
}

.i-title-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
  33% {
    transform: translateY(-50px);
  }
  66% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(-150px);
  }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #0077b6;
  display: flex;
  align-items: center;
}

.i-img {
  max-width: 35%;
  position: relative;
  border-radius: 8%;
}

.i-img-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}


.i-bg {
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);  background-color: #0077b6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.i-button-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-button {
  clip-path: circle(50% at 50% 50%);
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-button:hover {
  animation: hover 0.25s forwards;
}

.i-button:not(:hover) {
  animation: exitHover 0.25s forwards;
}

@keyframes hover {
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(6px);
  }
}

@keyframes exitHover {
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(-6px);
  }
}

.i-icon-holder {
  display: flex;
  flex-direction: row;
}

.i-icon {
  color: royalblue;
  align-items: center;
  font-weight: 300;
  object-fit: cover;
  margin: 0px 10px;
}

.i-icon-first {
  color: royalblue;
  align-items: center;
  font-weight: 300;
  object-fit: cover;
  margin: 0px -14px;
  margin-right: 10px;
}

@media only screen and (max-width: 1000px) {
  .i-right {
    display: none;
  }
  .i {
    display: flex;
    height: 50vh;
  }

  .i-left-wrapper {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .i-name {
    font-size: 50px;
  }

  .i-icon-holder {
    display: flex;
    flex-direction: row;
  }

  .i-title-item {
    height: 50px;
    font-size: 23px;
    font-weight: bold;
    color: #0077b6;
    display: flex;
    align-items: center;
  }
}

