.a {
    height: auto;
    display: flex;
    align-items: center;
}

.a-div {
    width: 75%;
    margin-bottom: 20px;
}

.a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.a-right {
    flex: 1;
    width: auto;
    height: auto;
}

.a-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #333;
}

.a-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.a-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.a-title {
    margin: 20px 0px;
    font-weight: bold;
}

.a-sub {
    margin: 20px 0px;
    width: 95%;
}

.a-desc {
    font-weight: 300;
    width: 95%;
    white-space: pre-line;
}

.a-credit {
    font-weight: 200;
    margin: 0px 200px;
}

.a-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.a-icon {
    max-height: 150px;
    max-width: 150px;
    margin: 20px 25px;
}

.a-div {
    width: 75%;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
}

.a-first {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.a-bottom {
    padding: 50px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.a-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.a-button {
    width: 50%;
    height: 100%;
    font-weight: bold;
    font-size: 3em;
    border-radius: 12px;
}

.a-button:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s;
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .a-first {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .a-div {
        width: 75%;
    }

    .a {
        height: fit-content;
        display: flex;
        align-items: center;
        padding: 25px;
    }

    .a-left {
        display: none;
    }

    .a-sub {
        margin: 20px 0px;
        width: 95%;
        word-wrap: break-word;
    }

    .a-desc {
        font-weight: 300;
        width: 95%;
        white-space: pre-line;
    }

    .a-credit {
        display: none;
    }

    .a-icon {
        max-height: 110px;
        max-width: 110px;
        margin: 20px 25px;
    }

    .a-button {
        width: 80%;
        height: 10%;
        font-weight: bold;
        font-size: 2em;
        border-radius: 12px;
    }


}
