.nav {
    display: flex;
    align-items: center;
    position: sticky;
    top: -5px;
    z-index: 2;
    height: 70px;
    min-height: 70px;
    width: calc(100%);
    background-color: #fff;
    box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
}

li {
    display: inline;
    margin: 0 1rem;
    color: black;
    font-size: 1.5rem;
    font-weight: 200;
    justify-content: right;
}

li:hover {
    cursor: pointer;
}

.icon {
    max-height: 70px;
    justify-content: left;
}

@media only screen and (max-width: 1000px) {
    .nav {
        display: flex;
        align-items: center;
        position: sticky;
        top: -5px;
        z-index: 2;
        height: 70px;
        min-height: 70px;
        min-width: 100%;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
    }

    li {
        display: inline;
        margin: 0 1rem;
        color: black;
        font-size: 1rem;
        font-weight: 200;
        justify-content: right;
    }

    .icon {
        max-height: 50%;
        justify-content: left;
    }
}
